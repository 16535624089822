<template>
  <BaseLayout>
    <section class="ol-body">
      <section class="ol-product">
        <div class="ol-wrapper ol-wrapper--large">
          <div class="ol-product-content add-fix">
            <div class="ol-product-content__center ol-product--404">
              <div class="ol-product-content__center-notfound">
                <div class="ol-cart-floating__empty">
                  <figure class="ol-cart-floating__empty-figure">
                    <img
                      src="@/assets/images/og-error.svg"
                      alt="Keranjang Belanja"
                      title="Keranjang Belanja"
                    />
                  </figure>

                  <h4 class="ol-cart-floating__empty-label">
                    Halaman Tidak di Temukan
                  </h4>

                  <p>
                    Kemungkinan halaman telah dihapus, atau Anda menulis URL
                    yang salah
                  </p>
                  <router-link to="/"  class="ol-btn">Kembali Berbelanja</router-link>
                </div>
              </div>

              <div class="ol-product-content ol-product-related add-fix"></div>
            </div>
          </div>
        </div>
      </section>

      <div class="ol-overlay-filter"></div>
      <a href="javascript:void(0)" class="ol-filter"
        ><i class="ol-fa fas fa-filter"></i
      ></a>

      <a href="javascript:void(0)" class="ol-totop"
        ><i class="ol-fa fas fa-arrow-up"></i
      ></a>
    </section>
  </BaseLayout>
</template>
<script>
import BaseLayout from "@/components/landing/BaseLayout.vue";
export default {
  name: "notFound",
  components: {
    BaseLayout,
  },
};
</script>
